import React from 'react';
import { AiOutlineDelete } from "react-icons/ai";


const EducationForm = ({ formData, setFormData }) => {
  const handleEducationChange = (index, field, value) => {
    const newEducation = [...formData.education];
    newEducation[index][field] = value;
    setFormData({ ...formData, education: newEducation });
  };

  const addEducation = () => {
    setFormData(prevState => ({
      ...prevState,
      education: [...prevState.education, { name: '', college: '', startDate: '', endDate: '' }]
    }));
  };

  const removeEducation = (index) => {
    const newEducation = formData.education.filter((_, i) => i !== index);
    setFormData({ ...formData, education: newEducation });
  };

  return (
    <div>
      {/* <h2>Education</h2> */}
      {formData.education.map((education, index) => (
        <div key={index}>
          <input
            className='text-input'
            type="text"
            placeholder="Degree Name"
            value={education.name}
            onChange={(e) => handleEducationChange(index, 'name', e.target.value)}
          />
          <input
            className='text-input'
            type="text"
            placeholder="College Name"
            value={education.college}
            onChange={(e) => handleEducationChange(index, 'college', e.target.value)}
          />
          <input
            className='text-input'
            type="text"
            placeholder="Start Date"
            value={education.startDate}
            onChange={(e) => handleEducationChange(index, 'startDate', e.target.value)}
          />
          <input
            className='text-input'
            type="text"
            placeholder="End Date"
            value={education.endDate}
            onChange={(e) => handleEducationChange(index, 'endDate', e.target.value)}
          />
          <button className='remove-button' type="button" onClick={() => removeEducation(index)}><AiOutlineDelete /></button>
        </div>
      ))}
                <button className='add-button' type="button" onClick={addEducation}>Add Education</button>

    </div>
  );
};

export default EducationForm;
