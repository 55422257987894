import React from 'react';
import { Document, Page, Text, View, StyleSheet, Link, Font, Image } from '@react-pdf/renderer';
Font.register({
  family: 'Computer Modern',
  fonts: [
    { src: '/Computer Modern_2.ttf' },
    { src: '/Computer Modern_3.ttf', fontWeight: 'bold' }, // Roboto Bold
    { src: '/times-new-roman-italic.ttf', fontStyle: 'italic' }, // Roboto Italic
  ],
});

// Define styles
const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 12,
    fontFamily: 'Computer Modern',
    fontWeight: 'normal'
  },
  header: {
    fontSize: 26,
    textAlign: 'center',
    marginBottom: 5,
    //fontStyle:'italic',
  },
  summary: {
    color: '#fffff',
    fontWeight: 'normal',
  },
  contactDetails: {
    textAlign: 'center',
    marginBottom: 10,
    fontSize: 10,
  },
  section: {
    marginBottom: 15,
  },
  heading: {
    fontSize: 14,
    marginBottom: 10,
    textTransform: 'uppercase',
    borderBottom: '1px solid black',
    fontWeight: 'bold',
    color: '#fffff',
  },

  link: {
    color: '#fffff',
    textDecoration: 'none',
  },
  spaceBetween: {
    marginRight: 10, // Adds space after each link
  },
  experience: {
    marginBottom: 10,
  },
  // bulletPoint: {
  //   marginLeft: 10,
  // },
  skillContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  skill: {

  },
  sts1: {
    fontSize: 12,
    marginVertical: 2,
    width: '100%',
    flexDirection: 'row',
  },
  sts2: {
    fontWeight: 'bold',
    fontSize: 12,
    width: '30%',
  },
  sts3: {
    fontSize: 12,
    width: '70%',
    paddingLeft: 5,
  },
  childTitle: {
    fontSize: 13,
    fontWeight: 'bold',
  },
  icon: {
    width: 8,
    height: 7,
    marginRight: 5,
  },
  expMain: {
    marginVertical: 2,
    width: '100%',
    flexDirection: 'row',
  },
  expSub1: {
    fontWeight: 'bold',
    width: '2%',
  },
  expSub2: {
    width: '98%',
    paddingLeft: 5,
  },
  proMain: {
    marginVertical: 2,
    width: '100%',
    flexDirection: 'row',
  },
  proSub1: {
    fontWeight: 'bold',
    width: '2%',
    marginLeft:10,
  },
  proSub2: {
    width: '98%',
    paddingLeft: 5,
  },
});

const ResumePDF = ({ formData }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.header}>{formData.name}</Text>
      <View style={styles.contactDetails}>
        {formData.mobile && formData.address && formData.mobile.length > 1 && (
          <Text style={{ marginBottom: 10 }}>
            <Image style={styles.icon} src="/phone.png" />{' '}
            {formData.mobile}
            {' '}<Image style={styles.icon} src="/location.png" />{' '}
            {formData.address}</Text>
        )}
        <Text>
          {/* Email */}
          {formData.email && formData.email.length > 1 && (
            <Text style={styles.spaceBetween}>
              <Image style={styles.icon} src="/mail.png" />{' '}
              <Link style={styles.link} src={`mailto:${formData.email}`}>{formData.email}</Link>
            </Text>
          )}
          {' '}
          {formData.link1 && formData.link1.length > 1 && (
            <Text style={styles.spaceBetween}>
              {' '} <Image style={styles.icon} src="/linkedin.png" />{' '}
              <Link style={styles.link} src={formData.link1}>{formData.link1}</Link>
            </Text>
          )}
          {formData.link2 && formData.link2.length > 1 && (

            <Text style={styles.spaceBetween}>
              {' '} <Image style={styles.icon} src="/link.png" />{' '}
              <Link style={styles.link} src={formData.link2}>{formData.link2}</Link>
            </Text>
          )}
        </Text>
      </View>

      {formData.objective && formData.objective.length > 0 && (
        <View style={styles.section}>
          <Text style={styles.heading}>Summary</Text>
          <Text>{formData.objective}</Text>
        </View>
      )}

      {formData.skills && formData.skills.length > 0 && (
        <View style={styles.section}>
          <Text style={styles.heading}>Skills</Text>
          <View style={styles.skillContainer}>
            {formData.skills.map((skill, index) => (
              <View key={index} style={styles.skill}>
                {skill.items.map((item, idx) => (
                  <View style={styles.sts1} key={idx}>
                    <View style={styles.sts2}>
                      <Text style={styles.childTitle}>{skill.title}</Text>
                    </View>
                    <View style={styles.sts3}>
                      <Text>{item}</Text>
                    </View>
                  </View>
                ))}
              </View>
            ))}
          </View>
        </View>
      )}


      {formData.experiences && formData.experiences.length > 0 && (
        <View style={styles.section}>
          <Text style={styles.heading}>Experience</Text>
          {formData.experiences.map((experience, index) => (
            <View key={index}>
              <Text style={styles.childTitle}>
                {experience.designation} - {experience.company}
              </Text>

              <Text>{experience.startDate} - {experience.endDate}</Text>

              <View style={{ marginLeft: 10 }}>
                {experience.workSummary.map((point, idx) => (
                  <View key={idx} style={styles.expMain}>
                    <View style={styles.expSub1}> <Text style={{ fontWeight: 'bold', fontSize: 12 }}>•</Text></View>
                    <View style={styles.expSub2}> <Text style={{ fontWeight: 'normal', fontFamily: 'Computer Modern', }}>
                      {point}
                    </Text>
                    </View>

                  </View>
                ))}
              </View>
            </View>
          ))}
        </View>
      )}



      {formData.projects && formData.projects.length > 0 && (
        <View style={styles.section}>
          <Text style={styles.heading}>Projects</Text>
          {formData.projects.map((project, index) => (
            <View key={index}>
              <Text style={styles.childTitle}>{project.name}</Text>

              {project.summary.map((point, idx) => (
                <View key={idx} style={styles.proMain}>
                  <View style={styles.proSub1}> <Text style={{ fontWeight: 'bold', fontSize: 12 }}>•</Text></View>
                  <View style={styles.proSub2}> <Text style={{ fontWeight: 'normal', fontFamily: 'Computer Modern', }}>
                    {point}
                  </Text>
                  </View>
                </View>
              ))}
            </View>

          ))}
        </View>
      )}

      {formData.education && formData.education.length > 0 && (
        <View style={styles.section}>
          <Text style={styles.heading}>Education</Text>
          {formData.education.map((edu, index) => (
            <View key={index}>
              <Text style={styles.childTitle}>{edu.name} - {edu.college}</Text>
              <Text>{edu.startDate} - {edu.endDate}</Text>
            </View>
          ))}
        </View>
      )}

      {formData.certifications && formData.certifications.length > 0 && (
        <View style={styles.section}>
          <Text style={styles.heading}>Certifications</Text>
          {formData.certifications.map((certification, index) => (
            <View key={index}>
              <Text style={styles.childTitle}>
                {certification.name} - {certification.institution}
              </Text>
              <Text>{certification.startDate} - {certification.endDate}</Text>
            </View>
          ))}
        </View>
      )}
    </Page>
  </Document>
);

export default ResumePDF;
