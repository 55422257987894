import React from 'react';
import { AiOutlineDelete } from "react-icons/ai";
const SkillsForm = ({ formData, setFormData }) => {
  // Handle changes to skill fields (title, items)
  const handleSkillChange = (index, field, value) => {
    const newSkills = [...formData.skills];
    newSkills[index][field] = value;
    setFormData({ ...formData, skills: newSkills });
  };

  // Handle changes to individual skill items
  const handleItemChange = (skillIndex, itemIndex, value) => {
    const newSkills = [...formData.skills];
    newSkills[skillIndex].items[itemIndex] = value;
    setFormData({ ...formData, skills: newSkills });
  };

  // Add a new skill
  const addSkill = () => {
    setFormData(prevState => ({
      ...prevState,
      skills: [...prevState.skills, { title: '', items: [''] }]
    }));
  };

  // Add a new skill item to a specific skill
  const addItem = (skillIndex) => {
    const newSkills = [...formData.skills];
    newSkills[skillIndex].items.push('');
    setFormData({ ...formData, skills: newSkills });
  };

  // Remove a specific skill
  const removeSkill = (skillIndex) => {
    const newSkills = formData.skills.filter((_, index) => index !== skillIndex);
    setFormData({ ...formData, skills: newSkills });
  };

  // Remove a specific item from a skill
  const removeItem = (skillIndex, itemIndex) => {
    const newSkills = [...formData.skills];
    newSkills[skillIndex].items = newSkills[skillIndex].items.filter((_, index) => index !== itemIndex);
    setFormData({ ...formData, skills: newSkills });
  };

  return (
    <div>
      {/* <h2>Skills</h2> */}
      {formData.skills.map((skill, skillIndex) => (
        <div key={skillIndex}>
          <label className="input-label">Skills Title like Programming Languages, Framework, Soft skills etc. </label>
          <br/>
          <input
            className='text-input'
            type="text"
            placeholder="Skill Title"
            value={skill.title}
            onChange={(e) => handleSkillChange(skillIndex, 'title', e.target.value)}
          />
          <br/>
          
          {skill.items.map((item, itemIndex) => (
            <div key={itemIndex}>
               <label className="input-label">Skills item like Java, Problem solving, MS Excel etc. </label>
               <br/>
              <input
                className='text-input'
                type="text"
                placeholder="Skill Item"
                value={item}
                onChange={(e) => handleItemChange(skillIndex, itemIndex, e.target.value)}
              />
              {/* <button
              className='remove-button'
                type="button"
                onClick={() => removeItem(skillIndex, itemIndex)}
              >
                <AiOutlineDelete />
              </button> */}
            </div>
          ))}

          {/* <button className='add-button' type="button" onClick={() => addItem(skillIndex)}>Add Item</button> */}
          <button className='remove-button' type="button" onClick={() => removeSkill(skillIndex)}><AiOutlineDelete /></button>
        </div>
      ))}
      <button className='add-button' type="button" onClick={addSkill}>Add Skill</button>
    </div>
  );
};

export default SkillsForm;
