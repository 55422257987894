import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FaRegUserCircle } from "react-icons/fa";
import { Link } from 'react-router-dom';


const HeaderNavbar = ({ isAuthenticated }) => {

  return (
    <Navbar expand="lg" className="custom-navbar">
      <Container fluid>
        <Navbar.Brand>
          <Link className='logo-a' to="/">
            <div class="logo">
          <div class="logo-text">Free ATS</div>
          <div class="logo-subtext">Resume</div>
        </div>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my- my-lg-0"
            style={{ maxHeight: '80px' }}
            navbarScroll
          >
            <Link to="/" style={{ marginRight: '50px' }}>
              {/* <Button>Home</Button> */}
            </Link>
            <Link to="/create-resume">
              <Button>Create Resume</Button>
            </Link>
          </Nav>

        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default HeaderNavbar;