import React from 'react';
import { AiOutlineDelete } from "react-icons/ai";

const CertificationsForm = ({ formData, setFormData }) => {
  const handleCertificationChange = (index, field, value) => {
    const newCertifications = [...formData.certifications];
    newCertifications[index][field] = value;
    setFormData({ ...formData, certifications: newCertifications });
  };

  const addCertification = () => {
    setFormData(prevState => ({
      ...prevState,
      certifications: [...prevState.certifications, { name: '', institution: '', startDate: '', endDate: '' }]
    }));
  };

  const removeCertification = (index) => {
    const newCertifications = formData.certifications.filter((_, i) => i !== index);
    setFormData({ ...formData, certifications: newCertifications });
  };

  return (
    <div>
      {/* <h2>Certifications</h2> */}
      {formData.certifications.map((cert, index) => (
        <div key={index}>
          <input
            className="text-input"
            type="text"
            placeholder="Certification Name"
            value={cert.name}
            onChange={(e) => handleCertificationChange(index, 'name', e.target.value)}
          />
          <input
            className="text-input"
            type="text"
            placeholder="Institution"
            value={cert.institution}
            onChange={(e) => handleCertificationChange(index, 'institution', e.target.value)}
          />
          <input
            className="text-input"
            type="text"
            placeholder="Start Date"
            value={cert.startDate}
            onChange={(e) => handleCertificationChange(index, 'startDate', e.target.value)}
          />
          <input
            className="text-input"
            type="text"
            placeholder="End Date"
            value={cert.endDate}
            onChange={(e) => handleCertificationChange(index, 'endDate', e.target.value)}
          />
          <button className='remove-button' type="button" onClick={() => removeCertification(index)}><AiOutlineDelete /></button>
        </div>
      ))}
      <button className='add-button' type="button" onClick={addCertification}>Add Certification</button>
    </div>
  );
};

export default CertificationsForm;
