import React from 'react';

const HeaderForm = ({ formData, setFormData }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <div>
      {/* <h2>Basic Details</h2> */}
      <label className="input-label">enter your full nmae</label>
      <br />
      <input className='text-input' type="text" name="name" value={formData.name} onChange={handleInputChange} placeholder="Full Name" />
      <br />
      <label className="input-label">Enter your address</label>
      <br />

      <input className='text-input' type="text" name="address" value={formData.address} onChange={handleInputChange} placeholder="Address" />

      <br />
      <label className="input-label">Enter your Mobile number</label>
      <br />
      <input className='text-input' type="text" name="mobile" value={formData.mobile} onChange={handleInputChange} placeholder="Mobile No" />
      <br />
      <label className="input-label">Enter your Email</label>
      <br />
      <input className='text-input' type="email" name="email" value={formData.email} onChange={handleInputChange} placeholder="Email" />
      <br />
      <label className="input-label">Enter your linkedin</label>
      <br />
      <input className='text-input' type="url" name="link1" value={formData.link1} onChange={handleInputChange} placeholder="linkedin url" />
      <label className="input-label">Enter your Portfolio or github link</label>
      <br />
      <input className='text-input' type="url" name="link2" value={formData.link2} onChange={handleInputChange} placeholder=" portfolio or github" />
    </div>
  );
};

export default HeaderForm;
