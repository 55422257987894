import React from 'react';
import { AiOutlineDelete } from "react-icons/ai";

const ExperienceForm = ({ formData, setFormData }) => {
  const handleExperienceChange = (index, field, value) => {
    const newExperience = [...formData.experiences];
    newExperience[index][field] = value;
    setFormData({ ...formData, experiences: newExperience });
  };

  const handleWorkSummaryChange = (experienceIndex, summaryIndex, value) => {
    const newExperience = [...formData.experiences];
    newExperience[experienceIndex].workSummary[summaryIndex] = value;
    setFormData({ ...formData, experiences: newExperience });
  };

  const addWorkSummaryItem = (experienceIndex) => {
    const newExperience = [...formData.experiences];
    newExperience[experienceIndex].workSummary.push('');
    setFormData({ ...formData, experiences: newExperience });
  };

  const removeWorkSummaryItem = (experienceIndex, summaryIndex) => {
    const newExperience = [...formData.experiences];
    newExperience[experienceIndex].workSummary = newExperience[experienceIndex].workSummary.filter((_, idx) => idx !== summaryIndex);
    setFormData({ ...formData, experiences: newExperience });
  };

  const addExperience = () => {
    setFormData(prevState => ({
      ...prevState,
      experiences: [...prevState.experiences, { designation: '', company: '', workSummary: [''], startDate: '', endDate: '' }]
    }));
  };

  const removeExperience = (index) => {
    const newExperience = formData.experiences.filter((_, i) => i !== index);
    setFormData({ ...formData, experiences: newExperience });
  };

  return (
    <div>
      {/* <h2>Experience</h2> */}
      {formData.experiences.map((experience, index) => (
        <div key={index}>
          <label className="input-label">Enter your Designation</label>
          <br/>
          <input
            className='text-input'
            type="text"
            placeholder="Designation"
            value={experience.designation}
            onChange={(e) => handleExperienceChange(index, 'designation', e.target.value)}
          />
          <br/>
              <label className="input-label">Enter company</label>
              <br/>
          <input
            className='text-input'
            type="text"
            placeholder="Company"
            value={experience.company}
            onChange={(e) => handleExperienceChange(index, 'company', e.target.value)}
          /><br/>
              <label className="input-label">Enter joined data</label>
              <br/>
          <input
            className='text-input'
            type="text"
            placeholder="Start Date"
            value={experience.startDate}
            onChange={(e) => handleExperienceChange(index, 'startDate', e.target.value)}
          /><br/>
              <label className="input-label">Enter end data</label>
              <br/>
          <input
            className='text-input'
            type="text"
            placeholder="End Date"
            value={experience.endDate}
            onChange={(e) => handleExperienceChange(index, 'endDate', e.target.value)}
          /><br/>

          <div>
            {experience.workSummary.map((summary, summaryIndex) => (
              <div key={summaryIndex}>
                    <label className="input-label">add your work summary . Note - must use number for better score</label>
                    <br/>
                <input
                  className='text-input'
                  type="text"
                  placeholder="Work Summary"
                  value={summary}
                  onChange={(e) => handleWorkSummaryChange(index, summaryIndex, e.target.value)}
                />
                <button className='remove-button' type="button" onClick={() => removeWorkSummaryItem(index, summaryIndex)}><AiOutlineDelete /></button>
              </div>
            ))}
            <button className='add-button' type="button" onClick={() => addWorkSummaryItem(index)}>Add Work Summary</button>
          </div>

          <button className='remove-button' type="button" onClick={() => removeExperience(index)}><AiOutlineDelete /></button>
        </div>
      ))}
      <button className='add-button' type="button" onClick={addExperience}>Add Experience</button>
    </div>
  );
};

export default ExperienceForm;
