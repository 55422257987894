import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const CollapsibleSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="collapsible-section">
      <div className="collapsible-header" onClick={toggle}>
        <span className="header-title">{title}</span>
        <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} className="icon" />
      </div>
      {isOpen && <div className="collapsible-content">{children}</div>}
    </div>
  );
};

export default CollapsibleSection;