import React, { useState, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import HeaderForm from './HeaderForm';
import ObjectiveForm from './ObjectiveForm';
import SkillsForm from './SkillsForm';
import ExperienceForm from './ExperienceForm';
import ProjectsForm from './ProjectsForm';
import EducationForm from './EducationForm';
import CertificationsForm from './CertificationsForm';
import ResumePreview from './ResumePreview';
import ResumeDownload from './ResumePreview';
import ViewResume from './DataView';
import ResumeViewer from './ResumePDFViewer';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderNavbar from './HeaderNavbar';
import CreateResume from './CreateResume';
import Home from './Home';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import FetchDataFromSheet from './FetchDataFromSheet';

function App() {
  return (
  <>
  <BrowserRouter>
      <div>
        <HeaderNavbar/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/create-resume" element={<CreateResume />} />
        </Routes>
      </div>
    </BrowserRouter> 
  </>
  );
}

export default App;
