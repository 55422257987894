import React, { useState, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import HeaderForm from './HeaderForm';
import ObjectiveForm from './ObjectiveForm';
import SkillsForm from './SkillsForm';
import ExperienceForm from './ExperienceForm';
import ProjectsForm from './ProjectsForm';
import EducationForm from './EducationForm';
import CertificationsForm from './CertificationsForm';
import ResumeViewer from './ResumePDFViewer';
import 'bootstrap/dist/css/bootstrap.min.css';
import CollapsibleSection from './CollapsibleSection';
import { Document, Packer, Paragraph, TextRun, HeadingLevel } from 'docx';
import { saveAs } from 'file-saver';
import ResumeDownload from './ResumePreview';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
function CreateResume() {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    link1: '',
    link2: '',
    objective: '',
    skills: [{ title: '', items: [''] }],
    experiences: [{ designation: '', company: '', workSummary: [''], startDate: '', endDate: '' }],
    projects: [{ name: '', summary: [''] }],
    education: [{ name: '', college: '', startDate: '', endDate: '' }],
    certifications: [{ name: '', institution: '', startDate: '', endDate: '' }]
  });

  const componentRef = useRef();

  useEffect(() => {
    const savedData = sessionStorage.getItem('resumeData');
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    sessionStorage.setItem('resumeData', JSON.stringify(formData));
    alert("Data saved to session storage!");
  };

  const downloadWordDocument = () => {
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              text: formData.name,
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph(`Mobile: ${formData.mobile}`),
            new Paragraph(`Email: ${formData.email}`),
            new Paragraph(`LinkedIn: ${formData.link1}`),
            new Paragraph(`Other Link: ${formData.link2}`),
            new Paragraph({ text: "Objective", heading: HeadingLevel.HEADING_2 }),
            new Paragraph(formData.objective),
            new Paragraph({ text: "Skills", heading: HeadingLevel.HEADING_2 }),
            ...formData.skills.map(skill => [
              new Paragraph({ text: skill.title, heading: HeadingLevel.HEADING_3 }),
              ...skill.items.map(item => new Paragraph(item)),
            ]).flat(),
            new Paragraph({ text: "Experience", heading: HeadingLevel.HEADING_2 }),
            ...formData.experiences.map(exp => [
              new Paragraph({ text: exp.designation, heading: HeadingLevel.HEADING_3 }),
              new Paragraph(`Company: ${exp.company}`),
              new Paragraph(`Start Date: ${exp.startDate}`),
              new Paragraph(`End Date: ${exp.endDate}`),
              ...exp.workSummary.map(summary => new Paragraph(summary)),
            ]).flat(),
            new Paragraph({ text: "Projects", heading: HeadingLevel.HEADING_2 }),
            ...formData.projects.map(proj => [
              new Paragraph({ text: proj.name, heading: HeadingLevel.HEADING_3 }),
              ...proj.summary.map(summary => new Paragraph(summary)),
            ]).flat(),
            new Paragraph({ text: "Education", heading: HeadingLevel.HEADING_2 }),
            ...formData.education.map(edu => [
              new Paragraph({ text: edu.name, heading: HeadingLevel.HEADING_3 }),
              new Paragraph(`College: ${edu.college}`),
              new Paragraph(`Start Date: ${edu.startDate}`),
              new Paragraph(`End Date: ${edu.endDate}`),
            ]).flat(),
            new Paragraph({ text: "Certifications", heading: HeadingLevel.HEADING_2 }),
            ...formData.certifications.map(cert => [
              new Paragraph({ text: cert.name, heading: HeadingLevel.HEADING_3 }),
              new Paragraph(`Institution: ${cert.institution}`),
              new Paragraph(`Start Date: ${cert.startDate}`),
              new Paragraph(`End Date: ${cert.endDate}`),
            ]).flat(),
          ],
        },
      ],
    });

    Packer.toBlob(doc).then(blob => {
      saveAs(blob, "resume.docx");
    });
  };

  return (
   


    <div className='create-resume'>
    <div className="mt-4">
      <div className="row">
        <div className="col-lg-5 col-md-12 left">
          <form onSubmit={handleSubmit}>
            <CollapsibleSection title="Basic Details">
              <HeaderForm formData={formData} setFormData={setFormData} />
            </CollapsibleSection>

            <CollapsibleSection title="Add Objective">
              <ObjectiveForm formData={formData} setFormData={setFormData} />
            </CollapsibleSection>

            <CollapsibleSection title="Add Skills">
              <SkillsForm formData={formData} setFormData={setFormData} />
            </CollapsibleSection>

            <CollapsibleSection title="Add Experience">
              <ExperienceForm formData={formData} setFormData={setFormData} />
            </CollapsibleSection>

            <CollapsibleSection title="Add Projects">
              <ProjectsForm formData={formData} setFormData={setFormData} />
            </CollapsibleSection>

            <CollapsibleSection title="Add Education">
              <EducationForm formData={formData} setFormData={setFormData} />
            </CollapsibleSection>

            <CollapsibleSection title="Add Certifications">
              <CertificationsForm formData={formData} setFormData={setFormData} />
            </CollapsibleSection>

            {/* <button type="submit" className="btn btn-primary">Save to Session Storage</button> */}
          </form>
        </div>

        <div className="col-lg-7 col-md-12 right">
        <div className="download-btn">
                <button onClick={downloadWordDocument} className="download-btn-doc">Download Word</button>
                <ResumeDownload formData={formData} />
              </div>
              <div className="resume-preview" ref={componentRef}>
                <ResumeViewer formData={formData} />
              </div>
        </div>
      </div>
    </div>
  </div>

  );
}
export default CreateResume;
