import React from 'react';
import { AiOutlineDelete } from "react-icons/ai";

const ProjectsForm = ({ formData, setFormData }) => {
  // Handle changes to project fields (name, summary)
  const handleProjectChange = (index, field, value) => {
    const newProjects = [...formData.projects];
    newProjects[index][field] = value;
    setFormData({ ...formData, projects: newProjects });
  };

  // Handle changes to individual summary items within a project
  const handleSummaryChange = (projectIndex, summaryIndex, value) => {
    const newProjects = [...formData.projects];
    newProjects[projectIndex].summary[summaryIndex] = value;
    setFormData({ ...formData, projects: newProjects });
  };

  // Add a new project with an empty name and summary
  const addProject = () => {
    setFormData(prevState => ({
      ...prevState,
      projects: [...prevState.projects, { name: '', summary: [''] }]
    }));
  };

  // Add a new summary item to a specific project
  const addSummaryItem = (projectIndex) => {
    const newProjects = [...formData.projects];
    newProjects[projectIndex].summary.push('');
    setFormData({ ...formData, projects: newProjects });
  };

  // Remove a specific project
  const removeProject = (projectIndex) => {
    const newProjects = formData.projects.filter((_, index) => index !== projectIndex);
    setFormData({ ...formData, projects: newProjects });
  };

  // Remove a specific summary item from a project
  const removeSummaryItem = (projectIndex, summaryIndex) => {
    const newProjects = [...formData.projects];
    newProjects[projectIndex].summary = newProjects[projectIndex].summary.filter((_, idx) => idx !== summaryIndex);
    setFormData({ ...formData, projects: newProjects });
  };

  return (
    <div>
      {/* <h2>Projects</h2> */}
      {formData.projects.map((project, projectIndex) => (
        <div key={projectIndex}>
              <label className="input-label">Enter Project name</label>
              <br/>
          <input
            className='text-input'
            type="text"
            placeholder="Project Name"
            value={project.name}
            onChange={(e) => handleProjectChange(projectIndex, 'name', e.target.value)}
          />

          <div>
            {project.summary.map((summary, summaryIndex) => (
              <div key={summaryIndex}>
                    <label className="input-label">Enter project details Note- must use number </label>
                    <br/>
                <input
                  className='text-input'
                  type="text"
                  placeholder="Summary"
                  value={summary}
                  onChange={(e) => handleSummaryChange(projectIndex, summaryIndex, e.target.value)}
                />
                <button
                className='remove-button'
                  type="button"
                  onClick={() => removeSummaryItem(projectIndex, summaryIndex)}
                >
                  <AiOutlineDelete />
                </button>
              </div>
            ))}
            <button className='add-button' type="button" onClick={() => addSummaryItem(projectIndex)}>
              Add Summary Item
            </button>
          </div>

          <button className='remove-button' type="button" onClick={() => removeProject(projectIndex)}>
          <AiOutlineDelete />
          </button>
        </div>
      ))}
      <button className='add-button' type="button" onClick={addProject}>Add Project</button>
    </div>
  );
};

export default ProjectsForm;
