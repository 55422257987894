import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import resumeImage from './HomeResumeImg.webp';
import CardGroup from 'react-bootstrap/CardGroup';



const Home = () => {
  const [showContent, setShowContent] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
  });

  // Function to toggle content visibility
  const toggleContent = (index) => {
    setShowContent((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  return (
    <div>
      <div class="home-container">
        <div class="content">
          <h1 className='h1-tag'>Fast and Simple Online Free ATS Resume Builder </h1>
          <div class="meta">
          </div>
          <p>Create an ATS-friendly resume for free. Optimize your resume to pass through Applicant Tracking
            Systems and increase your chances of landing a job</p>

          <Link to="/create-resume" class="cta-button">Start Creating Resume</Link>
        </div>
        <div class="image-container">
          <img src={resumeImage} alt="Resume Builder Image" />
        </div>
      </div>
      <div className='section-2'>
        {/* 
                <div className='section-2-left'>
                    <h2>What is an ATS Resume?</h2>
                    <p>An <strong>ATS resume</strong> is a resume specifically formatted to pass through Applicant Tracking Systems used by recruiters and employers. These systems are designed to screen, rank, and sort resumes based on keywords, skills, and other job-related criteria. If your resume isn’t formatted properly or lacks the right keywords, it could be automatically rejected, even if you’re highly qualified.</p>

                </div>
                <div className='section-2-right'>
                    <h2>Why Do You Need an ATS Resume?</h2>
                    <p>Most companies, especially large organizations, use ATS to manage job applications. According to research, over <strong>75% of resumes</strong> are rejected by ATS before a human ever sees them. This means that even a small formatting error or missing keyword could cost you the job. By creating an ATS-optimized resume, you can increase your chances of being noticed and landing an interview.</p>
                </div> */}
        <CardGroup>
          <Card>
            <Card.Body>
              <Card.Title><h2>What is an ATS Resume?</h2></Card.Title>
              <Card.Text>
              <p>An <strong>ATS resume</strong> is a resume specifically formatted to pass through Applicant Tracking Systems 
              used by recruiters and employers. These systems are designed to screen, rank, 
              and sort resumes based on keywords, skills, and other job-related criteria.
               If your resume isn’t formatted properly or lacks the right keywords,
               x it could be automatically rejected, even if you’re highly qualified.</p>
              </Card.Text>
            </Card.Body>
            {/* <Card.Footer>
              <small className="text-muted">Last updated 3 mins ago</small>
            </Card.Footer> */}
          </Card>
          {/* <Card>
            <Card.Img variant="top" src="holder.js/100px160" />
            <Card.Body>
              <Card.Title>Card title</Card.Title>
              <Card.Text>
                This card has supporting text below as a natural lead-in to
                additional content.{' '}
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <small className="text-muted">Last updated 3 mins ago</small>
            </Card.Footer>
          </Card> */}
          <Card>
            <Card.Body>
              <Card.Title><h2>Why Do You Need an ATS Resume?</h2></Card.Title>
              <Card.Text>
              <p>Most companies, especially large organizations, use ATS to manage job applications.
                 According to research, over <strong>75% of resumes</strong> are rejected by ATS 
                 before a human ever sees them. This means that even a small formatting error or
                  missing keyword could cost you the job. By creating an ATS-optimized resume, 
                  you can increase your chances of being noticed and landing an interview.</p>
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>

      </div>

      <div className='section-1'>
        <h2>How to Create an Applicant Tracking System-Friendly Resume</h2>
        <p>In today’s competitive job market, having an ATS-friendly resume is essential for getting noticed
           by employers. An <strong>Applicant Tracking System (ATS)</strong> is
            software that scans resumes to filter the most relevant candidates before they even 
            reach the hiring manager. To make sure your resume passes through the ATS, 
            it needs to be well-optimized with the right structure, format, and keywords. 
            In this blog, we’ll explore what a free ATS resume is, why it’s important, 
            and how you can create one that stands out.</p>
      </div>
      <div className='section-3'>
        <div>
          <h2>Key Tips for Creating a Free ATS Resume</h2>

          <ul>
            <li>
              <strong>Use Standard Fonts and Formatting</strong>

              <button className='toggle-btn' onClick={() => toggleContent(1)}>{showContent[1] ? '-' : '+'}</button>
              {showContent[1] && (
                <p>
                  Stick to simple fonts like Arial, Calibri, or Times New Roman.
                  Avoid using fancy templates, graphics, or unusual font sizes.
                  ATS systems prefer plain text, so fancy designs might not be readable by the system.
                </p>
              )}
            </li>

            <li>
              <strong>Optimize for Keywords</strong>

              <button className='toggle-btn' onClick={() => toggleContent(2)}>{showContent[2] ? '-' : '+'}</button>
              {showContent[2] && (
                <p>
                  Tailor your resume to the job description by including relevant keywords.
                  Focus on the skills, qualifications, and experiences that match the job posting.
                  Keywords like "project management," "data analysis," or "software development"
                  (depending on the role) will help your resume rank higher in the ATS.
                </p>
              )}
            </li>

            <li>
              <strong>Use a Simple Resume Layout</strong>

              <button className='toggle-btn' onClick={() => toggleContent(3)}>{showContent[3] ? '-' : '+'}</button>
              {showContent[3] && (
                <p>
                  Ensure your resume has clear sections with standard headings like "Work Experience," "Education," and "Skills."
                  ATS software relies on these headings to understand your qualifications. Avoid tables, columns, and non-standard
                  sections that might confuse the system.
                </p>
              )}
            </li>

            <li>
              <strong>Choose the Right File Format</strong>

              <button className='toggle-btn' onClick={() => toggleContent(4)}>{showContent[4] ? '-' : '+'}</button>
              {showContent[4] && (
                <p>
                  Always submit your resume in either a .docx or .pdf format unless specified otherwise in the job listing.
                  Some ATS systems struggle with parsing .pdf files, so when in doubt, stick to .docx for better readability.
                </p>
              )}
            </li>

            <li>
              <strong>List Skills and Experiences Properly</strong>

              <button className='toggle-btn' onClick={() => toggleContent(5)}>{showContent[5] ? '-' : '+'}</button>
              {showContent[5] && (
                <p>
                  Use bullet points to describe your achievements and responsibilities in each role. Be concise but detailed. For instance:
                  <ul>
                    <li>Managed a team of 10 developers to successfully complete software projects on time.</li>
                    <li>Analyzed data to improve marketing strategies, resulting in a 20% increase in lead generation.</li>
                  </ul>
                </p>

              )}
            </li>

            <li>
              <strong>Use Online Tools for Free ATS Resume</strong>

              <button className='toggle-btn' onClick={() => toggleContent(6)}>{showContent[6] ? '-' : '+'}</button>
              {showContent[6] && (
                <p>
                  There are many free tools available online that can help you create an ATS-friendly resume.
                  Websites like Jobscan, Resumake, and NovoResume provide free ATS resume templates and keyword optimization tips.
                  These tools allow you to scan your resume against job descriptions to ensure it’s ATS-ready.
                </p>
              )}
            </li>

            <li>
              <strong>Check for ATS Compliance</strong>

              <button className='toggle-btn' onClick={() => toggleContent(7)}>{showContent[7] ? '-' : '+'}</button>
              {showContent[7] && (
                <p>
                  After crafting your resume, use an ATS checker tool to scan it and get insights into its ATS-friendliness.
                  These tools highlight areas for improvement, suggest keywords, and ensure your formatting is suitable for ATS software.
                </p>
              )}
            </li>
          </ul>
        </div>

      </div>
    </div>
  );
};

export default Home;