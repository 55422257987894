import React from 'react';
import { AiOutlineDelete } from "react-icons/ai";

const ObjectiveForm = ({ formData, setFormData }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleRemove = () => {
    setFormData(prevState => ({
      ...prevState,
      objective: ''
    }));
  };  
  
  return (
    <div>
      <textarea
      className='objective-text'
        name="objective"
        value={formData.objective}
        onChange={handleInputChange}
        placeholder="Objective"
      />
      <br />
      <button className='remove-button' onClick={handleRemove}><AiOutlineDelete /></button>
    </div>
  );
};

export default ObjectiveForm;
