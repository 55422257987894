import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import ResumePDF from './ResumePDF';

const ResumeViewer = ({ formData }) => (
  <div className="resume-view-container">
    <PDFViewer className="pdf-viewer">
      <ResumePDF formData={formData} />
    </PDFViewer>
  </div>
);

export default ResumeViewer;
