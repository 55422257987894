import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ResumePDF from './ResumePDF';  // Assuming the above file is saved as ResumePDF.js

const ResumeDownload = ({ formData }) => {
  return (
   <button className='download-btn-pdf'>
     
     <PDFDownloadLink document={<ResumePDF formData={formData} />} fileName={`${formData.name}_Resume.pdf`} >
        {({ loading }) => (loading ? 'Loading document...' : 'Download pdf')}
      </PDFDownloadLink>
   </button>

    
  );
};

export default ResumeDownload;
